export type SortOption = {
  id: string;
  label: string,
}

const SortOptions = [
  {
    id: 'NEWEST',
    label: "Newest"
  },
  {
    id: 'BEST_SELLING',
    label: "Best Selling"
  },
  {
    id: 'HIGHEST_PRICE',
    label: "Price High"
  },
  {
    id: 'LOWEST_PRICE',
    label: "Price Low"
  },
  {
    id: 'A_TO_Z',
    label: "A to Z"
  },
  {
    id: 'Z_TO_A',
    label: "Z to A"
  },
] as SortOption[];

export default SortOptions;