import { Controller, UseFormReturn } from "react-hook-form";

import { AddressWrapper } from "./AddressForm.styled";
import ErrorMessage from "../../Input/InputError/InputError";
import GoogleAutocomplete from "components/common/Google/GoogleAutocomplete";
import InputSelect from "../../InputSelect/InputSelect";
import { InputText } from "../../Input/Input";
import Label from "../../Label/Label";
import React, { useEffect } from "react";
import { SelectOption } from "ts/types";
import { normalizePlace } from "utilities/autocompleteUtil";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

type AddressSchema = {
	address1: string;
	address2: string;
	city: string;
	postal_code: string;
	state_or_province: string;
	country_code: string;
};

interface Props {
	form: UseFormReturn<AddressSchema & any>;
	countryOptions: SelectOption[];
}

const AddressForm: React.FC<Props> = ({ form, countryOptions }: Props) => {
	const { t } = useTranslation();
	const {
		register,
		setValue,
		formState: { errors },
		control,
	} = form;

	const locale =
		useSelector((state: any) => state.location.selectedLocale) || "rs";

	const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
		const address1 = form.getValues().address1;

		// clear the previous address data in form.
		// this prevents a bug with the address input not clearing after the first use
		const emptyData = normalizePlace();
		Object.keys(emptyData).forEach((key) => {
			if (key in emptyData) {
				form.resetField(key);
			}
		});

		const data = normalizePlace(place);
		Object.entries(data).forEach(([key, value]) => {
			if (key in data) {
				if (key === "address1" && !value) {
					setValue(key, address1);
				} else {
					setValue(key, value);
				}
			}
		});
	};
	const GetError = (field: string): string => {
		const error = errors[field];
		return error && error.message ? t(error.message.toString()): "";
	};

	useEffect(() => {
		if(countryOptions.length === 1){
			setValue("country_code", countryOptions[0].value)
		}
	},[])

    return <>
        <h4 className="form__heading">{t("address_details")}</h4>
        <div className="form__fields">
            <div className="form__row">
			<InputText
                    label={t("form.registerTown") as string}
                    required
                    {...register("city")}
                    errorMsg={GetError("city")}
                />
				<Controller
                    control={control}
                    name="address1"
                    render={({ field, fieldState: { error } }) => (
                        <AddressWrapper>
                            <Label required htmlFor={field.name}>
                                {t("form.registerAddressLine1")}
                            </Label>
                            {/* TODO this has been commented out 
                            to reduce the number of Google Cloud API calls
                            the previous value of `apiKey` was `process.env.REACT_APP_GOOGLE_API_KEY || ""`

                            see https://app.activecollab.com/226056/projects/762/tasks/99829
                             */}
                            <GoogleAutocomplete
                                apiKey={""}
                                googleApi={{
                                    map: document.createElement("div"),
                                    api: (window as any).google?.maps,
                                }}
                                onChange={(e) => setValue('address1', e)}
                                isAutocompleteEnabled
                                onSelect={handlePlaceSelected}
                                language={locale}
                            />
                            {error?.message && <ErrorMessage message={t("form.validation.address1.required").toString()} />}
                        </AddressWrapper>
                    )}
                />
               
            </div>
            <div className="form__row">
			<InputText
                    label={ t("form.registerAddressLine2") as string}
                    required
                    {...register("address2")}
                    errorMsg={GetError("address2")}
                />
				
                <Controller
                    control={control}
                    name="country_code"
                    render={({ field }) => (
                        <InputSelect
                            {...field}
                            value={
                                countryOptions.length === 1
                                    ? countryOptions[0].value
                                    : field.value
                            }
                            label={t("form.registerCountry") as string}
                            required
                            options={countryOptions}
                            isSearchable
                            errorMsg={
                                (errors.country_code?.message &&
                                    t(errors.country_code?.message.toString())) ||
                                ""
                            }
                        />
                    )}
                />
            </div>
            <div className="form__row">
                <InputText
                    label={t("form.registerZipCode") as string}
                    required
                    {...register("postal_code")}
                    errorMsg={
                        (errors.postal_code?.message &&
                            t(errors.postal_code?.message.toString())) ||
                        ""
                    }
                />
            </div>
        </div>
    </>
}

export default AddressForm;