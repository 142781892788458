import { useLazyGetCategoryViaSearchQuery } from "app/api/category/categoryApi";
import SortOptions from "components/plp/PlpSort/SortOptions";
import { useEffect, useState } from "react";
import { Category, Product, ProductVariant } from "ts/types";
import { filterLabels } from "utilities/variables/filterLabels";
import { getSessionStorageCache, getSessionStorageOriginalProducts, setSessionStorageCache, setSessionStorageOriginalProducts } from "utilities/variables/localStorageUtils";

export interface FilterValues {
    label: string;
    value: string;
    count: number;
    isSelected: boolean;
    subCategories: FilterValues[];
}

export interface Filter {
    label: string;
    isCollapsedByDefault: boolean;
    __typename: string;
    values?: FilterValues[];
    maxPrice: string | number;
    minPrice: string | number;
}

const useCategoryPagination = (category: Category | undefined) => {
    const sessionStorageCache = getSessionStorageCache();
    const sessionStorageOriginalProducts = getSessionStorageOriginalProducts();
    
    const [products, setProducts] = useState<Product[]>(sessionStorageCache?.products || []);
    const [originalProducts, setOriginalProducts] = useState<Product[]>(sessionStorageOriginalProducts);
    const [filters, setFilters] = useState<Filter[]>(sessionStorageCache?.filters || []);
    const [pagination, setPagination] = useState<{
        endCursor: string;
        startCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
    }>(sessionStorageCache?.pagination || null);
    const [loading, setLoading] = useState(true);
    const [uninitialized, setUninitialized] = useState(!sessionStorageCache);

    const [nextProductSetCursor, setNextProductSetCursor] = useState("");
    const [concatNextProductSet, setConcatNextProductSet] = useState(false);
    const [toFilterAttributes, setToFilterAttributes] = useState<
        {
            attribute: string;
            values: string[];
        }[]
    >([]);
    const [toFilterCategoryIds, setToFilterCategoryIds] = useState<number[]>(
        [category?.entityId || 0].filter(Boolean),
    );
    const [toFilterMinPrice, setToFilterMinPrice] = useState<number>();
    const [toFilterMaxPrice, setToFilterMaxPrice] = useState<number>();

    const [productSortOrder, setProductSortOrder] = useState(SortOptions[0].id);
    const [sizeCounts, setSizeCounts] = useState<{ [key: string]: number }>({});

    const [trigger] = useLazyGetCategoryViaSearchQuery();

    useEffect(() => {
        if (loading && category && sessionStorageCache) {
            if (sessionStorageCache.lastViewedCategory === category.entityId) {
                setLoading(false);
            }
        }
    }, [sessionStorageCache]);

    useEffect(() => {
        if (
            category?.entityId &&
            sessionStorageCache.lastViewedCategory !== category?.entityId
        ) {
            setUninitialized(false);
            setLoading(true);

            trigger({
                entityId: category.entityId,
                sort: productSortOrder,
            })
                .unwrap()
                .then((res) => {
                    const fetchedProducts = res?.products || [];
                    setProducts(fetchedProducts);
                    setOriginalProducts(fetchedProducts);
                    setFilters(res?.filters || []);
                    setPagination(res?.pageInfo);

                    setSessionStorageCache({
                        products: fetchedProducts,
                        filters: res?.filters,
                        pagination: res?.pageInfo,
                        lastViewedCategory: category?.entityId,
                    });

                    setSessionStorageOriginalProducts(fetchedProducts);

                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [category]);

    // Calculate sizeCounts based on initial products only
    useEffect(() => {
        const initialSizeCounts: { [key: string]: number } = {};
        originalProducts.forEach((product: { variants?: ProductVariant[]; }) => {
            product.variants?.forEach((variant: { sku: string; }) => {
                const size = extractSizeFromSku(variant.sku);
                if (size) {
                    initialSizeCounts[size] = (initialSizeCounts[size] || 0) + 1;
                }
            });
        });
        setSizeCounts(initialSizeCounts);
    }, [originalProducts]);

    useEffect(() => {
        if (category?.entityId) {
            setLoading(true);

            const filterParams = {
                ...(concatNextProductSet && {
                    nextProductSetCursor,
                }),
                filterAttributes: toFilterAttributes,
                filterCategoryIds: toFilterCategoryIds.length
                    ? toFilterCategoryIds
                    : Array.from(new Set([...toFilterCategoryIds, category.entityId])),
                ...((toFilterMinPrice || toFilterMaxPrice) && {
                    filterPrice: {
                        ...(toFilterMinPrice &&
                            toFilterMinPrice > 0 && {
                                minPrice: toFilterMinPrice,
                            }),
                        ...(toFilterMaxPrice &&
                            toFilterMaxPrice > 0 && {
                                maxPrice: toFilterMaxPrice,
                            }),
                    },
                }),
                sort: productSortOrder,
            };

            trigger(filterParams)
                .unwrap()
                .then((res) => {
                    let filteredProducts = res?.products || [];

                    const isSizeFilterApplied = toFilterAttributes.some(attr => 
                        filterLabels.includes(attr.attribute)
                    );

                    if (isSizeFilterApplied) {
                        const sizeValues = toFilterAttributes.find(attr => 
                            filterLabels.includes(attr.attribute)
                        )?.values;

                        // Union logic for size filter
                        if (sizeValues) {
                            const sizeFilteredProducts: Product[] = [];
                            sizeValues.forEach(size => {
                                const sizeProducts = filteredProducts.filter((product: { variants: any[]; }) =>
                                    product.variants.some((variant: { sku: string | string[]; }) => {
                                        return variant.sku.includes(`/${size}`);
                                    })
                                );
                                sizeFilteredProducts.push(...sizeProducts);
                            });
                            filteredProducts = Array.from(new Set(sizeFilteredProducts)); // Remove duplicates
                        }
                    }

                    if (concatNextProductSet) {
                        setProducts(products.concat(filteredProducts));
                    } else {
                        setProducts(filteredProducts);
                    }
                    setFilters(res?.filters || []);
                    setPagination(res?.pageInfo);

                    setSessionStorageCache({
                        products: concatNextProductSet
                            ? products.concat(filteredProducts)
                            : filteredProducts,
                        filters: res?.filters,
                        pagination: res?.pageInfo,
                        lastViewedCategory: category?.entityId,
                    });

                    // Recalculate size counts only if non-size filters are applied
                    if (!isSizeFilterApplied) {
                        const newSizeCounts: { [key: string]: number } = {};
                        filteredProducts.forEach((product: { variants?: ProductVariant[]; }) => {
                            product.variants?.forEach((variant: { sku: string; }) => {
                                const size = extractSizeFromSku(variant.sku);
                                if (size) {
                                    newSizeCounts[size] = (newSizeCounts[size] || 0) + 1;
                                }
                            });
                        });
                        setSizeCounts(newSizeCounts);
                    }
                })
                .finally(() => {
                    setUninitialized(false);
                    setLoading(false);
                    setConcatNextProductSet(false);
                });
        }
    }, [
        toFilterAttributes,
        toFilterCategoryIds,
        toFilterMinPrice,
        toFilterMaxPrice,
        productSortOrder,
        nextProductSetCursor,
    ]);

    useEffect(() => {
        if (filters.length > 0) {
            const updatedFilters = filters.map((filter) => {
                if (filterLabels.includes(filter.label)) {
                    const updatedValues = filter.values?.map((value) => ({
                        ...value,
                        count: sizeCounts[value.value] || 0,
                    }));
                    return {
                        ...filter,
                        values: updatedValues,
                    };
                } else {
                    return filter;
                }
            });
    
            if (JSON.stringify(updatedFilters) !== JSON.stringify(filters)) {
                setFilters(updatedFilters);
            }
        }
    }, [sizeCounts, filters]);

    const getNextProductSet = () => {
        if (pagination?.endCursor) {
            setNextProductSetCursor(pagination.endCursor);
            setConcatNextProductSet(true);
        }
    };

    const filterProductsByAttribute = (
        filters: {
            attribute: string;
            values: string[];
        }[],
    ) => {
        setToFilterAttributes(filters);
    };

    const filterProductsByCategoryIds = (ids: number[]) => {
        setToFilterCategoryIds([...ids, category?.entityId || 0].filter(Boolean));
    };

    const filterProductsByPrice = (
        minPrice: number | undefined,
        maxPrice: number | undefined,
    ) => {
        if (typeof minPrice !== "undefined" || (minPrice && minPrice > 0)) {
            setToFilterMinPrice(minPrice);
        }

        if (typeof maxPrice !== "undefined" || (maxPrice && maxPrice > 0)) {
            setToFilterMaxPrice(maxPrice);
        }
    };

    const setSortOrder = (sort: string) => {
        setProductSortOrder(sort);
    };

    const clearFilters = () => {
        const categoryIds = category?.entityId ? [category.entityId] : [];
        setToFilterCategoryIds(categoryIds);
        setToFilterAttributes([]);
        setToFilterMaxPrice(0);
        setToFilterMinPrice(0);
    };

    const extractSizeFromSku = (sku: string) => {
        const matches = sku.match(/\/(\d+)$/); 
        return matches ? matches[1] : null; 
    };

    return {
        uninitialized,
        loading,
        products,
        filters,
        pagination,
        clearFilters,
        getNextProductSet,
        filterProductsByAttribute,
        filterProductsByCategoryIds,
        filterProductsByPrice,
        setSortOrder,
        sizeCounts,
    };
};

export default useCategoryPagination;
