import { useSelector } from "redux/hooks";

import { wordPressApi } from "app/api/wordpress/wordPressApi";

import ButtonWithIcon from "components/common/ui/Button/ButtonWithIcon/ButtonWithIcon";
import Link from "components/common/ui/Link/Link";
import Page from "components/common/ui/Page/Page";
import Price from "components/common/ui/Price/Price";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import AccountProductCard from "components/ProductCard/AccountOrder/AccountProductCard";
import { PageType } from "ts/enums";

import { ReactComponent as Padlock } from "../../assets/images/svg/Checkout Securely White.svg";

import { MenuSlug } from "app/constants/menuSlugs";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import useDataLayer from "datalayer/useDataLayer";
import useCart from "hooks/cart/useCart";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import uuid from "react-uuid";
import PathTranslationKey from "utilities/paths";
import { PORTAL_MODAL } from "utilities/portals";
import {
	StyledBasketContent,
	StyledBasketWrapper,
	StyledCartInfoSection,
	StyledErrorContent,
	StyledLoadingOverlay,
	StyledProductsSection,
} from "./Basket.Styled";

const Basket = () => {
	const { cart, meta } = useCart();
	const settings = wordPressApi.useGetGlobalStoreSettingsQuery();
	const isFetching = false;

	const [isCartLoading, setIsCartLoading] = useState(false);

	const { data: helpList, isLoading: isMenuLoading } =
		wordPressApi.useGetMenuItemsBySlugQuery(MenuSlug.CART_NEED_HELP);

	const { viewCart, beginCheckout } = useDataLayer();

	const { isLoading } = useSelector((state) => state.ui);
	const { customer } = useSelector((state) => state.auth);

	const { t } = useTranslation();

	useEffect(() => {
		if (!cart) return;

		viewCart(cart);
	}, [cart]);

	useEffect(() => {
		setTimeout(() => setIsCartLoading(true), 1500);
	}, []);

	const renderCartLoadingSpinner = () => {
		return (
			<Portal node={document && document.getElementById(PORTAL_MODAL)}>
				<StyledLoadingOverlay className="loading-container">
					<LoadingSpinner />
				</StyledLoadingOverlay>
			</Portal>
		);
	};

	const renderEmptyCart = () => {
		return (
			<StyledErrorContent heading={t("basket.emptyCart")}>
				<Link
					to={`${t(PathTranslationKey.CATEGORY)}${t(
						PathTranslationKey.SALE_CATEGORY,
					)}`}
				>
					{t("basket.addItem")}
				</Link>
			</StyledErrorContent>
		);
	};

	return (
		<Page pageType={PageType.CART}>
			<StyledBasketWrapper className="Basket">
				<h2>
					{t("basket.yourCart")} ({meta.totalItems})
				</h2>

				{!cart ? (
					!isCartLoading ? (
						renderCartLoadingSpinner()
					) : (
						renderEmptyCart()
					)
				) : (
					<>
						{isLoading || isFetching ? renderCartLoadingSpinner() : ""}
						{cart?.line_items?.physical_items?.length > 3 && (
							<ButtonWithIcon
								link={{ to: t(PathTranslationKey.CHECKOUT).toString() }}
								icon={<Padlock />}
								variant={"primary_01"}
								fullWidth
								className="basket-top-button checkout-button"
							>
								{t("basket.checkout")}
							</ButtonWithIcon>
						)}

						<StyledBasketContent className="StyledBasketContent">
							<StyledProductsSection className="StyledProductsSection">
								{cart?.line_items?.physical_items.map((item: any) => (
									<AccountProductCard
										triggeredFrom="CART"
										isBasket
										key={item.id}
										productDetails={item}
										bypassSkeleton={true}
									/>
								))}
							</StyledProductsSection>

							<StyledCartInfoSection className="StyledCartInfoSection">
								<div className="cart-totals">
									<p>
										{t("basket.subtotal")}:{" "}
										<span>
											{isLoading || isFetching ? (
												<Skeleton width={"10ch"} />
											) : (
												<Price price={Number(cart?.base_amount) || 0} />
											)}
										</span>
									</p>

									{customer && !!cart?.discount_amount && (
										<p>
											{t("basket.discount")}:{" "}
											<span>
												<Price price={Number(cart?.discount_amount)} />
											</span>
										</p>
									)}

									{!!cart?.coupons.length &&
										cart?.coupons.map((coupon: any) => (
											<>
												<p className="promo-code">
													{t("basket.promoDiscount")}:{" "}
													<span>
														{coupon?.discounted_amount} {t("basket.currency")}
													</span>
												</p>

												<p>
													{t("basket.promoCode")}: "{coupon.code}"{" "}
													{t("basket.promoCodeApplied")}
												</p>
											</>
										))}

									<hr />

									<p className="">
										{t("basket.total")}:
										<span>
											{isLoading || isFetching ? (
												<Skeleton width={"10ch"} />
											) : (
												<Price price={Number(cart?.cart_amount) || 0} />
											)}
										</span>
									</p>
									<ButtonWithIcon
										link={{ to: t(PathTranslationKey.CHECKOUT).toString() }}
										icon={<Padlock />}
										variant={"primary_01"}
										fullWidth
										className="checkout-button"
										onClick={() => beginCheckout(cart)}
									>
										{t("basket.checkout")}
									</ButtonWithIcon>
								</div>

								<Link className="continue-link" to="/">
									{t("basket.continueShopping")}
								</Link>

								<div className="payment-brands">
									<h3>{t("basket.monaAccepts")}</h3>
									{settings.isLoading && (
										<ul>
											{[...Array(4)].map((_, index) => (
												<li key={`payment-method-skeleton-${index}`}>
													<Skeleton height={24} width={40} />
												</li>
											))}
										</ul>
									)}
									<ul>
										{settings.data?.storeGeneralSettings.globalStoreSettings.paymentMethodsIcons?.map(
											(icon: any) => (
												<li key={uuid()}>
													<img
														src={icon.sourceUrl}
														alt={icon.altText || icon.title}
													/>
												</li>
											),
										)}
									</ul>
								</div>

								<div className="need-help">
									{!isMenuLoading && (
										<Fragment>
											<h3>{t("basket.needHelp")}</h3>
											<ul>
												{helpList.map((item: any) => {
													return (
														<li key={item.id}>
															<Link to={item.path}>{item.label}</Link>
														</li>
													);
												})}
											</ul>
										</Fragment>
									)}
								</div>
							</StyledCartInfoSection>
						</StyledBasketContent>
					</>
				)}
			</StyledBasketWrapper>
		</Page>
	);
};

export default Basket;
