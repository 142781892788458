import { WordpressImage } from "ts/types";

import {
  SkeletonBadges,
  SkeletonCards,
  StyledCardRow,
  StyledRow,
  StyledWrapper,
} from "./Cards.Styled";
import { FunctionComponent } from "react";

interface CardsProps {
  loading?: boolean;
  badges: WordpressImage[];
  paymentMethodIcons: WordpressImage[];
}

const Cards: FunctionComponent<CardsProps> = ({
  loading = false,
  badges,
  paymentMethodIcons,
}) => {
  if (loading) {
    return (
      <StyledWrapper>
        <StyledRow>
          <SkeletonBadges />
        </StyledRow>
        <StyledCardRow>
          <SkeletonCards />
        </StyledCardRow>
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      <StyledRow>
        {badges.map((badge) => {
          return (
            <img
              key={badge.id}
              title={badge.title}
              alt={badge.altText}
              src={badge.sourceUrl}
            />
          );
        })}
      </StyledRow>
      <StyledCardRow>
        {paymentMethodIcons.map((icon) => {
          return (
            <img
              key={icon.id}
              title={icon.title}
              alt={icon.altText}
              src={icon.sourceUrl}
            />
          );
        })}
      </StyledCardRow>
    </StyledWrapper>
  );
};

export default Cards;
