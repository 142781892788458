import { useEffect, useMemo, useState } from "react";

import ShoppingCart from "components/cart/ShoppingCart/ShoppingCart";
import Typography from "components/common/ui/Typography/Typography";
import AccountProductCard from "components/ProductCard/AccountOrder/AccountProductCard";

import useDataLayer from "datalayer/useDataLayer";
import useCart from "hooks/cart/useCart";
import { useLocation } from "react-router";
import PathTranslationKey from "utilities/paths";
import {
	EmptyCart,
	LoadingSpinnerOverlay,
	StyledAside,
	StyledBody,
	StyledButton,
	StyledFooter,
	StyledFooterButton,
} from "./MiniCart.Styled";

import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import Price from "components/common/ui/Price/Price";
import { useTranslation } from "react-i18next";
import { useSelector } from "redux/hooks";
import { useDebounce } from "usehooks-ts";
import Link from "components/common/ui/Link/Link";

const MiniCart = () => {
	const { t } = useTranslation();
	const location = useLocation();

	const datalayer = useDataLayer();

	const { cart, meta } = useCart();
	const [isOpen, setIsOpen] = useState(false);

	const showSpinnerDebounce = useDebounce(meta.isLoading, 300);

	const preventOpen = useMemo(() => {
		const path = location.pathname;

		const targetPaths = [
			t(PathTranslationKey.CART),
			t(PathTranslationKey.CHECKOUT),
		];

		return targetPaths.some((target) => path.endsWith(target));
	}, [t, location]);

	const handleOpenClose = (close: boolean) => {
		if (preventOpen) {
			return;
		}

		setIsOpen(!close);
	};

	useEffect(() => {
		if (isOpen) {
			datalayer.viewCart(cart);
		}
	}, [isOpen]);

	const isEmpty = useMemo(() => {
		return meta.totalItems === 0;
	}, [meta]);

	const doubleSizeButtons =
		(t("checkout").length > 16 && t("checkout").includes(" ")) ||
		(t("cart").length > 16 && t("cart").includes(" "));
	return (
		<>
			<StyledButton
				onClick={() => handleOpenClose(false)}
				icon={<ShoppingCart />}
			/>
			{isOpen && (
				<StyledAside
					position="right"
					isOpen={isOpen}
					close={() => handleOpenClose(true)}
					heading={`${t("cart")} (${meta.totalItems})`}
					footer={
						!isEmpty && (
							<StyledFooter>
								<div className="footer__price--container">
									<Typography>{t("total")}:</Typography>
									<Typography>
										<Price
											price={cart?.cart_amount || 0}
										/>
									</Typography>
								</div>
								<div className="footer__button--container">
									<StyledFooterButton
										doubleSize={doubleSizeButtons}
										variant="primary_01"
										link={{ to: t(PathTranslationKey.CHECKOUT).toString() }}
										onClick={() => handleOpenClose(true)}
									>
										{t("checkout")}
									</StyledFooterButton>
									<StyledFooterButton
										doubleSize={doubleSizeButtons}
										variant="primary_03"
										textLight
										link={{ to: t(PathTranslationKey.CART).toString() }}
										onClick={() => handleOpenClose(true)}
									>
										{t("cart")}
									</StyledFooterButton>
								</div>
							</StyledFooter>
						)
					}
				>
					<StyledBody>
						{!isEmpty && (
							<>
								{showSpinnerDebounce && (
									<LoadingSpinnerOverlay>
										<LoadingSpinner />
									</LoadingSpinnerOverlay>
								)}
								{cart?.line_items?.physical_items.map((item) => (
									<AccountProductCard
										triggeredFrom="CART"
										productDetails={item}
										key={item.id}
										isMiniBasket
										isFetching={meta.isLoading}
										bypassSkeleton
										handleOpenClose={handleOpenClose}
									/>
								))}
							</>
						)}
						{isEmpty && (
							<EmptyCart className="empty-cart">
								<Typography variant="heading" size="small">
									{t("basket.emptyCart")}
								</Typography>
								<Link
									to={`${t(PathTranslationKey.CATEGORY)}${t(PathTranslationKey.SALE_CATEGORY)}`}
									onClick={() => handleOpenClose(true)}
								>
									{t("basket.addItem")}
								</Link>
							</EmptyCart>
						)}
					</StyledBody>
				</StyledAside>
			)}
		</>
	);
};

export default MiniCart;
