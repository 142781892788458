interface MeasurementType {
	size: {
		[key: number]: string;
	};
	halfSizes: {
		[key: number]: number;
	};
}

export const measurementData: MeasurementType = {
	size: {
		3: "S",
		4: "M",
		5: "L",
		6: "XL",
		7: "XXL",
	},
	halfSizes: {
		70: 7,
		75: 7.5,
		80: 8,
		85: 8.5,
		90: 9,
		95: 9.5,
		100: 10,
		105: 10.5,
		110: 11,
	},
};

export const unitOfMeasure = (
	groupId: string | undefined,
	value: number | string,
) => {
	if (!groupId || groupId === undefined) {
		return value;
	}

	/* Check if product is in the display override list, if so return relevant value / value with measurement. If not return value as it is passed in. */
	const measurement: string = ["431"].includes(groupId)
		? "size"
		: ["661", "662", "663", "664", "665", "761", "762", "763", "764"].includes(
				groupId,
		  )
		? "cm"
		: ["771", "641"].includes(groupId)
		? "halfSizes"
		: "";

	if (measurement === "") {
		return value;
	} else if (measurement === "size" || measurement === "halfSizes") {
		const convertedValue =
			measurementData[measurement as keyof MeasurementType][value as number];
		return convertedValue;
	}
	return value + measurement;
};
